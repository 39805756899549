import React, { useEffect } from "react"
import ZoomMtgEmbedded from "@zoom/meetingsdk/embedded"
import Btn from "../custom/Button"
import { USER_NAME } from "../../constants/LocalStorageConstant"

const ZoomTest = (props) => {
  let zak = props?.detail?.start_url?.split("zak=")["1"]

  let client = ""
  try {
    client = ZoomMtgEmbedded.createClient()
  } catch (error) {
    client = null
  }

  let authEndpoint = "https://zoom-signature.onrender.com/"
  let sdkKey = "oyKr9J1Zs12UtfAovP9OT61GUjoIaJL6qjYG"
  let meetingNumber = props?.detail?.meeting_id
  let passWord = props?.detail?.password
  let role = 1
  let userName = ""
  let userEmail = ""
  let registrantToken = ""
  let zakToken = zak

  function getSignature(e) {
    e.preventDefault()

    fetch(authEndpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        startMeeting(response.signature)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  /* async function generateSignature() {
    let meetConfig = {
      training_id: props?.detail?.trainingId,
      meetingNumber: meetingNumber,
      userName: localStorage.getItem(USER_NAME),
      userEmail: localStorage.getItem(USER_NAME),
      passWord: passWord,
      role: 1,
      leaveUrl: isBrowser() ? `${window.location.origin}/app/dashboard` : "",
      lang: "en-US",
    }
    let res = await postRequest("/learner/generate_signature/", { meetConfig })
    if (res.success) {
      let sign = res.data.text
      startMeeting(sign)
    }
    console.log(res.data, "res")
  } */

  function startMeeting(signature) {
    let meetingSDKElement = document.getElementById("meetingSDKElement")
    let meetingSDKChatElement = document.getElementById("meetingSDKChatElement")

    userName = localStorage.getItem(USER_NAME)
    userEmail = localStorage.getItem(USER_NAME)
    console.log(userName, userEmail, "name")

    const fullSize = {
      isResizable: true,
      viewSizes: {
        default: {
          width: 1000,
          height: 600,
        },
        ribbon: {
          width: 300,
          height: 700,
        },
      },
    }

    client
      .init({
        zoomAppRoot: meetingSDKElement,
        language: "en-US",
        patchJsMedia: true,
        customize: {
          chat: {
            popper: {
              anchorElement: meetingSDKChatElement,
            },
          },
          video: props.type == "window" ? fullSize : {},
        },
      })
      .then(() => {
        client
          .join({
            signature: signature,
            sdkKey: sdkKey,
            meetingNumber: meetingNumber,
            password: passWord,
            userName: userName,
            userEmail: userEmail,
            tk: registrantToken,
            zak: zakToken,
          })
          .then(() => {
            console.log("joined successfully")
          })
          .catch((error) => {
            console.log(error)
          })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function handleNewWindow() {
    props.handleHide()
    window.open(
      `${process.env.GATSBY_REACT_APP_FRONTEND}/zoom-test?meeting_id=${meetingNumber}&password=${passWord}&start_url=${props?.detail?.start_url}&trainingId=${props?.detail?.trainingId}`,
      "",
      "height=570,width=520"
    )
  }

  function testBreakoutRoom() {
    let x = client.getBreakoutRoomStatus()
    console.log(x)
  }

  return (
    <div>
      <div>
        <h5>Zoom Meeting </h5>
        <div id="meetingSDKElement"></div>
        <Btn onClick={getSignature}>Join Meeting</Btn>
        {props.type !== "window" && <Btn onClick={handleNewWindow}>Open In New Window</Btn>}
        <Btn onClick={testBreakoutRoom}>Test BR</Btn>
      </div>
    </div>
  )
}

export default ZoomTest
