import React, { useEffect } from "react"
import ZoomTest from "../components/video-conference/zoom-test"
// import ZoomClient from "../components/video-conference/zoom-client"
import { useLocation } from "@reach/router"

const ZoomTestPage = () => {
  const location = useLocation()
  let params = new URLSearchParams(location.search)
  const detail = {
    meeting_id: params.get("meeting_id"),
    password: params.get("password"),
    start_url: params.get("start_url"),
    trainingId: params.get("trainingId"),
  }

  return (
    <div>
      <ZoomTest type="window" detail={detail} />
    </div>
  )
}

export default ZoomTestPage
